const projectDetails = [
  {
    img: 'lets-eat/lets-eat-base.png',
    title: "Let's Eat (Philippines)",
    info:
      'This was a food-delivery app that had a market Australia, and wanted to expand to their business to the Philippines',
    info2:
      "I was responsible for modifying and deploying the Swift app to have more features specifically for the Philippines, and meeting Apple's new requirements",
    url: '',
    repo: '',
    appstoreLink: 'https://apps.apple.com/ng/app/lets-eat-phillipines/id1462296828',
    playstoreLink: '',
    video: 'https://moso-portfolio-media.s3.eu-west-2.amazonaws.com/lets-eat-ph-record.mp4',
    poster: 'lets-eat/poster.png',
    screenshots: [
      {
        src: 'lets-eat/1.png',
        label: 'Add meal to cart',
      },
      {
        src: 'lets-eat/2.png',
        label: 'Feeds',
      },
      {
        src: 'lets-eat/3.png',
        label: 'View meal',
      },
      {
        src: 'lets-eat/4.png',
        label: 'Chat',
      },
    ],
  },
  {
    img: 'hlw/hlw-base.png',
    title: 'HLW Mobile',
    info:
      'HLW was a react-native app for a US-based architecture firm for their prospective clients to analyze and learn about structural ratings',
    info2:
      'I was responsible for developing the app, deploying the Android apk and iOS builds (via tesflight) for QA, and also writing extensive tests with jest and detox',
    url: '',
    repo: '',
    appstoreLink: '',
    video: 'https://moso-portfolio-media.s3.eu-west-2.amazonaws.com/hlw-preview.mp4',
    poster: 'hlw/poster.png',
    screenshots: [
      {
        src: 'hlw/1.png',
        label: 'Analyze Project',
      },
      {
        src: 'hlw/2.png',
        label: 'View case-study',
      },
      {
        src: 'hlw/3.png',
        label: 'Select familiarity',
      },
      {
        src: 'hlw/4.png',
        label: 'Research',
      },
    ],
    summaryVideo: 'hlw',
  },
  {
    img: 'sterling/sterling-base.png',
    title: 'Sterling OnePay',
    info:
      'OnePay is merchant an app for Serling bank that turns any mobile device to a POS (point of sale) device, and allows business/store owners to receive payments',
    info2:
      'I lead a team of 3 react-native devlopers in developing this app. I also worked closely with the product manager and back-end team to facilitate user-acceptance-test and vulnerability analysis.',
    url: '',
    repo: '',
    appstoreLink: '',
    playstoreLink: 'https://play.google.com/store/apps/details?id=ng.sterling_clane.onepay',
    video: 'https://moso-portfolio-media.s3.eu-west-2.amazonaws.com/sterling-preview.mp4',
    poster: 'sterling/poster.png',
    screenshots: [
      {
        src: 'sterling/1.png',
        label: 'Welcome screen',
      },
      {
        src: 'sterling/2.png',
        label: 'Settings',
      },
      {
        src: 'sterling/3.png',
        label: 'Terminal',
      },
      {
        src: 'sterling/4.png',
        label: 'Dashboard',
      },
    ],

    summaryVideo: 'sterling',
  },
  {
    img: 'clane-app/clane-app-base2.png',
    title: 'Clane app',
    info:
      'This was a next-gen wallet app for QR and Contactless payments in Africa still in development',
    info2:
      'I was the sole front-end developer for this react-native app, I particularly enjoyed implementing the complex animations in this project',
    url: '',
    repo: '',
    appstoreLink: 'https://apps.apple.com/ng/app/clane/id1436475431',
    playstoreLink: 'https://play.google.com/store/apps/details?id=com.clane.app',
    video: 'https://moso-portfolio-media.s3.eu-west-2.amazonaws.com/clane-app-record.mp4',
    poster: 'clane-app/poster.png',
    screenshots: [
      {
        src: 'clane-app/1.png',
        label: 'Menu',
      },
      {
        src: 'clane-app/2.png',
        label: 'Investments',
      },
      {
        src: 'clane-app/3.png',
        label: 'Get card',
      },
      {
        src: 'clane-app/4.png',
        label: 'Make Transfer',
      },
    ],
    summaryVideo: 'clane',
  },
];

module.exports = projectDetails;
