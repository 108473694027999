import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Image from '../Image/Image';
import downloadiOS from '../../images/download-ios.png'
import downloadAndroid from '../../images/download-android.png'

import projectDetails from '../../mock/details';


const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <h2 className="section-title-alt">My Works</h2>

          {projectDetails.map((project, index) => {
            const { title, info, info2, url, appstoreLink,playstoreLink, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={6} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={800}
                    delay={300}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div className="info">
                        <p className="info1">
                          {info ||
                            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                        </p>
                        <p className="mb-4 info2">{info2 || ''}</p>
                        <Row className="project-links-container">
                        <span className="vertical">
                          <Link to={`/${title}/`} target="_blank" rel="noopener noreferrer">
                            <div className="buttonTwo">
                              <div className="circle" />
                              More Details
                            </div>
                          </Link>
                        </span>

                        {appstoreLink&& (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={appstoreLink}
                            className={'download-badge-container'}
                          >
                            <img 
                            imgStyle={{
                              objectFit: 'contain',
                              objectPosition: '50% 50%',
                              width: '100%',
                              // borderWidth:1
                            }}
                            className="iOS-badge" 
                            src={downloadiOS}
                             />
                         </a>
                        )}
                       {playstoreLink&& (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={playstoreLink}
                            className={'download-badge-container'}
                          >
                            <img 
                            imgStyle={{
                              objectFit: 'contain',
                              objectPosition: '50% 50%',
                              width: '100%',
                              // borderWidth:1
                            }}
                            className="iOS-badge" 
                            src={downloadAndroid}
                             />
                         </a>)}
                        </Row>
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col lg={6} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={800}
                    delay={300}
                    distance="30px"
                  >
                    {/* <div className="project-wrapper__image"> */}
                    <Link to={`/${title}/`}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                          <Image
                            className="project-image-base"
                            alt={title}
                            src={img}
                            imgStyle={{
                              objectFit: 'contain',
                              objectPosition: '50% 50%',
                              width: '100%',
                              // borderWidth:1
                            }}
                            // height="700px"
                          />
                      </Link>
                    {/* </div> */}
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
